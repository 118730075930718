.page-title {
  margin: $space-2 0;
  @media screen and (max-width: 767px) {
    margin: $space-1 0;
  }

  &__h {
  }
  &__breadcrumbs {
  }
}
