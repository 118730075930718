.header {
  top: 0;
  position: sticky;
  z-index: 3;
  width: 100%;
  padding: $space-1 0;
  transition: $trans;
  background-color: $color-white;
  &--home {
    position: fixed;
  }

  &__container {
    @include container-max;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    transition: $trans;
    img {
      width: 170px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 150px;
        height: auto;
      }
    }
  }
  &__boton {
    background-color: $color-secondary;
    color: $color-white;
    padding: 0.7rem 2.125rem;
    font-family: $typo-secondary;
    border-radius: 3.125rem;
    margin-right: $space-1;
    text-decoration: none;
    transition: $trans;
    @media screen and (max-width: 767px) {
      display: none;
    }
    &:hover {
      color: $color-accent;
    }
  }
  &__boton-whats {
    img {
      height: 35px !important;
      width: auto;
      @media screen and (max-width: 767px) {
        height: 28px !important;
        width: auto;
      }
    }
  }
  &__nav {
    img {
      transition: $trans;
      width: 50px;
      height: auto;
    }
  }
}

.header__nav {
  a {
    .header__boton-whats {
      display: inline-block;
    }
  }
}

.scrolled {
  background-color: $color-white; /* Cambiar al color que desees */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Opcional: añadir sombra */
  transition: background-color 0.3s ease; /* Opcional: añadir transición suave */
  padding: calc($space-1/1.2) 0;
  transition: $trans;
  .header {
    &__logo {
      img {
        transition: $trans;
        width: 140px;
        height: auto;
      }
    }
    &__boton {
      background-color: $color-secondary;
      padding: 0.5rem 1.8rem;
      font-family: $typo-secondary;
      border-radius: 3.125rem;
      margin-right: $space-1;
      text-decoration: none;
      transition: $trans;

      &:hover {
        color: $color-accent;
      }
    }
    &__nav {
      transition: $trans;
      img {
        width: 40px;
        height: auto;
        transition: $trans;
      }
      nav {
        li {
          a {
            transition: $trans;
          }
          &.menu-item-contacto {
            background-color: $color-white;
            color: $color-primary;
            border-radius: 50px;
            padding: 0.3rem 0.7rem;
            margin-left: $space-1;
            transition: $trans;
            a {
              color: $color-primary;
              transition: $trans;
            }
          }
        }
      }
    }
  }
}
