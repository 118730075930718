.cta {
  padding: $space-3 0;
  background-color: $color-secondary;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    padding: $space-2 0 0 0;
  }
  &_container {
    @include container-max;
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: $space-3;
    img {
      width: 200px;
      height: auto;
      @media screen and (max-width: 767px) {
        width: 150px;
      }
    }
  }

  &__titulo {
    font-size: 5vw;
    font-family: $typo-secondary;
    color: $color-primary;
    text-transform: uppercase;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      text-align: center;
      font-size: $header-1;
      line-height: 1.2;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    a {
      border: solid 1px $color-primary;
      padding: $space-1 25vw;
      border-radius: 50px;
      text-decoration: none;
      text-transform: uppercase;
      font-size: $header-3;
      transition: $trans;
      @media screen and (max-width: 767px) {
        padding: $font-size 3rem;
        font-size: $header-4;
      }
      &:hover {
        background-color: $color-primary;
        color: $color-secondary;
        transition: $trans;
      }
    }
  }
}
