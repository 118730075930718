.scrolling-text {
  margin: 0;
  padding: 0;
  height: auto;
  overflow: hidden;
  transform: $trans;
  text-transform: uppercase;
  h1 {
    display: inline-block;
    margin: 0 0 0 -80px;
    padding: 0;
    font-family: sans-serif;
    position: relative;
    right: 150px;
    white-space: nowrap;
    font-size: 15vw;
    font-family: $typo-secondary;
    line-height: 1;
  }
}

.hero {
  margin: $space-2 0;
  @media screen and (max-width: 767px) {
    margin-top: $space-1;
  }
  &__container {
    @include container-max;
  }
  &__content {
    width: 85%;
    font-size: 3.5vw;
    font-weight: lighter;
    // text-wrap: balance;
    line-height: 1.3;
    margin: $space-1 0 $space-4 0;
    @media screen and (max-width: 767px) {
      font-size: 5vw;
      width: 100%;
    }
  }
  &__video {
    position: relative;
    width: 100%;
    overflow: hidden;

    video {
      width: 100%;
      height: 100vh; /* Ocupa la altura completa de la ventana en desktop */
      object-fit: cover;
      object-position: center;

      @media screen and (max-width: 767px) {
        height: 50vh; /* Ajusta la altura en dispositivos móviles */
      }
    }
  }
}
