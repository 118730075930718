.footer {
  padding: $space-1 0 $space-3 0;
  background-color: $color-secondary;
  font-size: $font-size-smaller;
  @media screen and (max-width: 767px) {
    padding: calc($space-5 * 1.3) 0 $space-4 0;
  }

  &__container {
    @include container-max;
  }

  &__content {
    color: $color-primary;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $space-2;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.2rem 3rem;
    }
  }

  &__item {
  }

  &__titulo {
    text-transform: uppercase;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      margin-bottom: 0.4rem;
    }
  }
  &__menu {
    a {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: lighter;
      &:hover {
        color: $color-primary;
      }
    }
  }
}

// NAVS FOOTER
.nav-servicios,
.nav-legal,
.nav-principal {
  ul {
    padding-left: 0;
  }
  li {
    list-style: none;
    margin-bottom: calc($space-1/4);
    @media screen and (max-width: 767px) {
      margin-bottom: 0.1rem;
    }
    a {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: lighter;
      &:hover {
        color: $color-primary;
      }
    }
  }
}
