.nav-primary {
  display: flex;
  align-items: center;
  &__items {
    display: flex;
    margin: 0;
    align-items: center;
    li.menu-item {
      list-style: none;
      &.menu-item-contacto {
        background-color: $color-primary;
        color: $color-white;
        border-radius: 50px;
        padding: 0.3rem 0.7rem;
        margin-left: $space-1;
        a {
          font-size: $font-size-big;
          &:hover {
            color: $color-white;
          }
        }
        &:hover {
          color: $color-white;
          background-color: $color-primary-dark;
        }
      }
      a {
        padding: 0 $font-size;
        text-decoration: none;
        color: $color-white;
        transition: $trans;
        font-size: $header-4;
      }
    }
    li.menu-item-has-children {
      position: relative;
      padding-right: 0.5rem;
      padding-right: $font-size;
      &::before {
        position: absolute;
        right: 10px;
        top: 4px;
        font-size: $header-4;
        content: "\eb3a";
        font-family: "unicons-line";
        color: $color-white;
      }
      .sub-menu {
        background-color: $color-white;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        padding: 0.5rem;
        display: none;
        position: absolute;
        box-shadow: $box-shadow;
        min-width: 220px;
        width: max-content;
        top: 2.5rem;
        z-index: 2;
        border-radius: 5px;
        li {
          a {
            color: $color-dark;
            font-size: $font-size;
            padding: 0.5rem 1.25rem;
            display: block;
            text-decoration: none;
            border-radius: 5px;
            &:hover {
              background-color: $color-primary-dark;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

.scrolled {
  .header {
    &__nav {
      transition: $trans;
      nav {
        li {
          a {
            transition: $trans;
          }
          &.menu-item-contacto {
            background-color: $color-white;
            color: $color-primary;
            border-radius: 50px;
            padding: 0.3rem 0.7rem;
            margin-left: $space-1;
            transition: $trans;
            a {
              color: $color-primary;
              transition: $trans;
            }
          }
        }
      }
    }
  }
}

body:not(.home) {
  .header {
    background-color: $color-white;
  }
  .nav-primary {
    &__items {
      li.menu-item {
        a {
          color: $color-dark;
          transition: $trans;
        }
        &.menu-item-contacto {
          background-color: $color-primary;
          color: $color-white;
          border-radius: 50px;
          padding: 0.3rem 0.7rem;
          margin-left: $space-1;
          a {
            font-size: $font-size-big;
            color: $color-white;
          }
          &:hover {
            color: $color-white;
            background-color: $color-primary-dark;
          }
        }
      }
      li.menu-item-has-children {
        &::before {
          color: $color-dark;
        }
      }
    }
  }

  .header.scrolled {
    background-color: $color-white; /* Cambiar al color que desees */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Opcional: añadir sombra */
    transition: background-color 0.3s ease; /* Opcional: añadir transición suave */
    padding: calc($space-1/1.2) 0;
    transition: $trans;
  }
}

#offcanvasMenu {
  width: 100vw;
  border: none;
}

.offcanvas {
  &-body {
    background-color: $color-accent-dark;
    padding-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    button {
      display: flex;
      margin-left: auto;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;

      i {
        color: $color-white;
        width: 80px;
        height: 80px;
        // &:hover {
        //   color: $color-primary;
        // }
      }
    }
  }
  &-button {
    position: absolute;
    top: 1rem;
    right: 0;
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &__nav {
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 1rem;
    li.menu-item {
      margin-bottom: 0.6rem;
      line-height: 1.2;
      @media screen and (max-width: 575px) {
        margin-bottom: 0.3rem;
      }
      & > a {
        font-size: $display-2;
        font-weight: $font-weight-normal;
        text-decoration: none;
        color: $color-white;
        @media screen and (max-width: 767px) {
          font-size: $header-2;
        }
      }
    }
  }
  &__logo {
    img {
      display: flex;
      width: 120px;
      margin: 1rem auto 0 auto;
      transition: $trans;
      @media screen and (max-width: 767px) {
        width: 180px;
      }
    }
  }
}
