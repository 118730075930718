.contacto {
  &__left {
    background-color: $color-grey-6;
  }

  &__left-inner {
    padding: $space-2;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1399px) {
      width: 95vw;
    }
    @media screen and (max-width: 1199px) {
      width: 95vw;
    }
    @media screen and (max-width: 991px) {
      padding-left: $space-1;
      padding-right: $space-1;
    }
    @media screen and (max-width: 767px) {
      padding-left: $font-size;
      padding-right: $font-size;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__right {
  }

  &__rigth-inner {
    padding: $space-2;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1399px) {
      width: 95vw;
    }
    @media screen and (max-width: 1199px) {
      width: 95vw;
    }
    @media screen and (max-width: 991px) {
      padding-left: $space-1;
      padding-right: $space-1;
    }
    @media screen and (max-width: 767px) {
      padding-left: $font-size;
      padding-right: $font-size;
      width: 100%;
      padding-bottom: 0;
      padding-top: calc($space-1/2);
    }
  }
  &__titular {
    font-size: $header-2;
    font-weight: lighter;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
    }
  }
  &__direccion {
    font-size: $font-size;
    font-weight: normal;
  }
  &__datos {
  }
  &__item {
    display: flex;
    border-bottom: solid 0.5px $color-grey-4;
    margin-bottom: $space-1;
    padding-bottom: $space-1;
    align-items: center;
    @media screen and (max-width: 767px) {
      margin-bottom: $font-size;
      padding-bottom: $font-size;
    }
  }
  &__dato {
    flex: 1;
    text-transform: uppercase;
    font-size: $font-size-small;
  }
  &__link {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    a {
      color: $color-secondary;
    }
    img {
      width: 50px;
      height: auto;
    }
  }
  &__titulo {
    font-size: $display-1;
    font-family: $typo-secondary;
    line-height: 1.1;
    width: 80%;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: $display-3;
    }
  }
  &__descripcion {
    width: 80%;
    font-size: $font-size-big;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: $font-size;
      text-wrap: balance;
    }
  }
}
.bg-grid {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-areas: "left right";
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    .contacto__left {
      order: 2;
    }
    .contacto__right {
      order: 1;
    }
  }
}
