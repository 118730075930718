.we-hero {
  @media screen and (max-width: 767px) {
    margin-top: $space-1;
  }
  &__container {
    @include container-max;
  }

  &__content {
  }

  &__descripcion {
    width: 85%;
    font-size: 3.5vw;
    font-weight: lighter;
    // text-wrap: balance;
    line-height: 1.3;
    margin: $space-1 0 $space-4 0;
    @media screen and (max-width: 767px) {
      font-size: 5vw;
      width: 100%;
    }
  }
}

.we-servicios {
  margin: $space-5 0;

  &__img {
    margin-bottom: $space-4;
    img {
      width: 100%;
      height: 70vh;
      object-fit: cover;
      @media screen and (max-width: 767px) {
        height: 30vh;
      }
    }
  }

  &__container {
    @include container-max;
  }

  &__content {
    grid-column-gap: 4vw;
    grid-row-gap: $space-4;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
    margin-bottom: $space-5;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  &__left {
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__pretitulo {
    font-size: $font-size-small;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    @media screen and (max-width: 767px) {
      writing-mode: initial;
    }
  }
  &__linea {
    height: 100%;
    width: 1px;
    flex: none;
    background-color: $color-grey-4;
    @media screen and (max-width: 767px) {
      width: 19%;
      height: 1px;
    }
  }
  &__right {
    grid-column-gap: 1vw;
    grid-row-gap: 6vw;
    grid-template-rows: auto;
    grid-template-columns: 3fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-row-gap: 3vw;
    }
  }

  &__titulo {
    font-size: $display-1;
    font-family: $typo-secondary;
    line-height: 1;
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
      font-size: calc($display-4 * 1.3);
    }
  }
  &__subtitulo {
    margin: $space-2 0;
    font-size: $header-2;
    font-family: $typo-primary;
    font-weight: $font-weight-light;
    text-transform: uppercase;
    line-height: 1.3;
    width: 90%;
    @media screen and (max-width: 767px) {
      font-size: $header-4;
      margin: $font-size-small 0;
    }
  }

  &__descripcion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    font-size: $font-size-big;
    font-weight: lighter;
    line-height: 1.6;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: $font-size-small;
    }
  }

  &__resumen {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: $font-size;
  }
  &__resumen-title {
    font-size: $font-size-small;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: $font-size;
    writing-mode: vertical-rl;
  }
  &__resumen-list {
    ul {
      list-style: none;
      border-left: solid 1px $color-grey-4;
      padding-left: $font-size;
    }
    li {
      font-size: $font-size-smaller;
      margin-bottom: calc($font-size/1.5);
      color: #544c43ae;
      a {
        color: $color-secondary;
        text-decoration: none;
        &:hover {
        }
      }
      @media screen and (max-width: 767px) {
        margin-bottom: calc($font-size/3);
      }
    }
  }
}
