.boda {
  margin: $space-3 0 calc($space-5 * 1.5) 0;
  &__container {
    @include container-max;
  }

  &__info {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: $space-3;
    margin-bottom: $space-3;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__descripcion {
    font-size: $header-2;
    // font-family: $typo-secondary;
    font-weight: lighter;
    text-wrap: balance;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      line-height: 1.2;
      margin-bottom: calc($space-1/2);
    }
  }

  &__proveedores {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: calc($space-1/2);
  }

  &__content {
    display: flex;
    border-bottom: solid 1px $color-secondary;
    margin-bottom: calc($font-size / 2);
    padding-bottom: calc($font-size / 2);
    text-transform: uppercase;
    @media screen and (max-width: 767px) {
    }
  }

  &__titulo {
    flex: 1;
    letter-spacing: 1px;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }

  &__proveedor {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }

  &__galeria {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
    img {
      width: 100%;
      height: 60vh;
      object-fit: cover;
      @media screen and (max-width: 767px) {
        height: 20vh;
      }
    }
  }
}
