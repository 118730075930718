html {
  scroll-behavior: smooth;
}
body {
  font-size: $font-size;
  color: $color-text-body;
  font-weight: $font-weight-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

a {
  color: $color-accent;
  transition: $trans;
  &:hover {
    text-decoration: none;
    transition: $trans;
  }
}
