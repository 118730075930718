.historias {
  margin-bottom: $space-5;
  &__container {
    @include container-max;
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    position: relative;
    overflow: hidden; /* Asegura que la imagen no se desborde al hacer zoom */
  }

  &__foto {
    img {
      width: 100%;
      height: 85vh;
      object-fit: cover;
      transition: transform 0.3s ease; /* Transición suave para el zoom */
      @media screen and (max-width: 767px) {
        height: 35vh;
      }
    }
  }

  &__titulo {
    position: absolute;
    z-index: 1;
    bottom: 2%;
    left: 6%;
    a {
      text-decoration: none;
      color: $color-primary;
      font-family: $typo-secondary;
      font-size: $display-4;
      transition: color 0.3s ease; /* Transición suave para el cambio de color */
    }
  }

  /* Efectos de hover */
  &__item:hover {
    .historias__foto img {
      transform: scale(1.1); /* Aplica el zoom */
    }

    .historias__titulo a {
      color: $color-primary-dark; /* Cambia el color al hacer hover */
    }
  }
}
