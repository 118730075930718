.bc-hero {
  @media screen and (max-width: 767px) {
    margin-top: $space-1;
  }
  &__container {
    @include container-max;
  }

  &__content {
  }

  &__descripcion {
    width: 85%;
    font-size: 3.5vw;
    font-weight: lighter;
    // text-wrap: balance;
    line-height: 1.3;
    margin: $space-1 0 $space-4 0;
    @media screen and (max-width: 767px) {
      font-size: 5vw;
      width: 100%;
    }
  }
  &__imagenes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: $space-4;
  }
  &__item {
    height: 500px;
    @media screen and (max-width: 767px) {
      height: 120px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-position: center;
    }
  }
}

.nosotros {
  margin-top: $space-4;
  padding-bottom: $space-3;
  border-bottom: 1px solid $color-grey-4;
  &__container {
    @include container-max;
  }

  &__content {
    grid-column-gap: 4vw;
    grid-row-gap: $space-4;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  &__left {
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__pretitulo {
    font-size: $font-size-small;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    @media screen and (max-width: 767px) {
      writing-mode: initial;
    }
  }
  &__linea {
    height: 100%;
    width: 1px;
    flex: none;
    background-color: $color-grey-4;
    @media screen and (max-width: 767px) {
      width: 19%;
      height: 1px;
    }
  }
  &__right {
    grid-column-gap: 6vw;
    grid-row-gap: 6vw;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-row-gap: 3vw;
    }
  }

  &__titulo {
    font-size: $display-1;
    font-family: $typo-secondary;
    text-transform: uppercase;
    line-height: 1;
    @media screen and (max-width: 767px) {
      font-size: $display-2;
    }
  }

  &__descripcion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    text-wrap: balance;
    font-size: $header-3;
    font-weight: lighter;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: $font-size;
    }
    span {
      display: inline-block;
      font-size: $header-2;
      font-weight: lighter;
      line-height: 1.2;
      margin-bottom: $space-1;
      @media screen and (max-width: 767px) {
        font-size: $header-3;
        margin-bottom: $font-size-small;
      }
    }
  }
}

.nosotros-proceso {
  margin: $space-5 0;
  @media screen and (max-width: 767px) {
    margin: $space-5 0 $space-3 0;
  }
  &__container {
    @include container-max;
  }
  &__content {
    grid-column-gap: 4vw;
    grid-row-gap: $space-4;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-row-gap: calc($space-1/4);
    }
  }

  &__left {
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__pretitulo {
    font-size: $font-size-small;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    @media screen and (max-width: 767px) {
      writing-mode: initial;
    }
  }
  &__linea {
    height: 100%;
    width: 1px;
    flex: none;
    background-color: $color-grey-4;
    @media screen and (max-width: 767px) {
      width: 19%;
      height: 1px;
    }
  }
  &__right {
  }

  &__claim {
    font-size: $header-2;
    font-weight: lighter;
    line-height: 1.4;
    text-wrap: balance;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }
}

.nosotros-servicios {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.5fr;
  grid-auto-columns: 1fr;
  display: grid;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
  &__imagen-wrapper {
    height: 100vh;
    position: sticky;
    top: 0;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      position: initial;
      height: auto;
    }
  }
  &__imagen-content {
    z-index: 1;
    flex-flow: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 4vw;
    display: flex;
    position: absolute;
    top: auto;
    bottom: 0%;
    right: 0%;
    width: 50%;
    text-align: right;
    @media screen and (max-width: 767px) {
      position: initial;
      width: 100%;
      text-align: left;
      margin-bottom: calc($space-1/2);
    }
    h2 {
      color: $color-white;
      font-size: $display-1;
      font-family: $typo-secondary;
      line-height: 1;
      @media screen and (max-width: 767px) {
        color: $color-grey-2;
        font-size: $display-3;
      }
    }
  }
  img {
    will-change: transform;
    transform: translate3d(0px, -0.0018%, 0px) scale3d(1.05, 1.05, 1)
      rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    transform-style: preserve-3d;
    @media screen and (max-width: 767px) {
      height: 350px;
    }
    .image-cover-parallax {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__servicios {
    grid-column-gap: 4vw;
    grid-row-gap: 4vw;
    background-color: $color-background;
    flex-flow: column;
    padding: 5vw;
    display: flex;
  }
  &__servicios-item {
    border-bottom: solid 1px $color-grey-4;
    padding-bottom: $space-3;
  }
  &__titulo {
    font-weight: 200;
    font-size: $header-2;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
      margin-bottom: 0.6rem;
    }
  }
  &__descripcion {
    font-size: $font-size-small;
    line-height: 1.6;
    font-weight: 200;
  }
}
