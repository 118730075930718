.home-content {
  margin: $space-5 0;
  &__container {
    @include container-max;
  }

  &__content {
    grid-column-gap: 4vw;
    grid-row-gap: $space-4;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }
  &__left {
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__pretitulo {
    font-size: $font-size-small;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    @media screen and (max-width: 767px) {
      writing-mode: initial;
    }
  }
  &__linea {
    height: 100%;
    width: 1px;
    flex: none;
    background-color: $color-grey-4;
    @media screen and (max-width: 767px) {
      width: 19%;
      height: 1px;
    }
  }
  &__right {
    grid-column-gap: 6vw;
    grid-row-gap: 6vw;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-row-gap: 3vw;
    }
  }

  &__titulo {
    font-size: $display-1;
    font-family: $typo-secondary;
    text-transform: uppercase;
    line-height: 1;
    @media screen and (max-width: 767px) {
      font-size: $display-2;
    }
  }

  &__descripcion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
    text-wrap: balance;
    font-size: $header-3;
    font-weight: lighter;
    @media screen and (max-width: 767px) {
      width: 100%;
      font-size: $font-size;
    }
    span {
      display: inline-block;
      font-size: $header-2;
      font-weight: lighter;
      line-height: 1.2;
      margin-bottom: $space-1;
      @media screen and (max-width: 767px) {
        font-size: $header-3;
        margin-bottom: $font-size-small;
      }
    }
  }
  &__actions {
    margin-top: $space-2;
    @media screen and (max-width: 767px) {
      margin-top: $font-size;
    }
  }
  &__menu {
    display: flex;
    flex: span 1;
    gap: $space-4;
    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  &__nav-title {
    font-size: $font-size-smaller;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: $font-size;
  }
  &__nav-item {
    text-transform: uppercase;
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
      margin-bottom: 0.4rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.nav-content {
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
  &__items {
    margin-bottom: 0px;
    li {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    a {
      font-size: $font-size;
      color: $color-text-body;
      text-decoration: none;
      text-transform: uppercase;
      letter-spacing: 1px;
      transform: $trans;
      &:hover {
        color: $color-secondary-light;
        transform: $trans;
      }
    }
  }
}

.roller-text {
  width: 100%;
  font-family: $typo-secondary;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  margin: $space-3 0;
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: fit-content;
    white-space: nowrap;
    animation: loop 30s linear infinite;
    border-top: 1px solid $color-grey-4;
    border-bottom: 1px solid $color-grey-4;
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    font-size: 90px;
    padding: 2rem 1rem;
    margin: 0rem 0rem 0rem 0rem;
    white-space: nowrap;
    @media screen and (max-width: 767px) {
      font-size: $display-3;
      padding: 1rem 0.5rem;
    }
  }
  @keyframes loop {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-50%);
    }
  }
}

.home-parallax {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.home-foto {
  @media screen and (min-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    padding-top: $space-5;
    img {
      width: 100%;
      height: 400px;
      object-fit: cover;
    }
  }
}

.parallax-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 70vh;
}

.parallax-item:first-child {
  background-image: url("../../dist/assets/site/home-service-01.jpg"); /* URL de tu icono personalizado */
  background-size: cover;
}

.parallax-item:nth-child(2) {
  background-image: url("../../dist/assets/site/home-service-02.jpg"); /* URL de tu icono personalizado */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.home-proceso {
  margin: $space-5 0;
  @media screen and (max-width: 767px) {
    margin: calc($space-5 * 2) 0 $space-5 0;
  }
  &__container {
    @include container-max;
  }
  &__content {
    grid-column-gap: 4vw;
    grid-row-gap: $space-4;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    display: grid;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.75rem;
    align-self: stretch;
    align-items: flex-start;
    display: flex;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__pretitulo {
    font-size: $font-size-small;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    @media screen and (max-width: 767px) {
      writing-mode: initial;
    }
  }
  &__linea {
    height: 100%;
    width: 1px;
    flex: none;
    background-color: $color-grey-4;
  }
  &__right {
  }

  &__claim {
    font-size: $display-2;
    font-weight: lighter;
    line-height: 1.4;
    text-wrap: balance;
    @media screen and (max-width: 767px) {
      font-size: $header-3;
    }
  }
}

.home-historias {
  margin: calc($space-5 * 1.2) 0 0 0;
  &__container {
    @include container-max;
  }

  &__header {
    display: flex;
    gap: $space-3;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__titulo {
    font-family: $typo-secondary;
    text-transform: uppercase;
    font-size: $display-3;
    flex-grow: 1;
    width: 50vw;
    line-height: 1.3;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__action {
    flex-grow: 3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }
  }
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &__item {
  }
  &__imagen {
    img {
      width: 100%;
      height: 57vh;
      object-fit: cover;
      @media screen and (max-width: 767px) {
        height: 30vh;
      }
    }
  }
}
